import { useTranslationWrapper } from "@npm-packages/translations";

export default function ShowCapitalizeFirstLetter({
  children = "",
  forceLower = true,
}) {
  try {
    let content = children
      ? forceLower
        ? children.toLowerCase()
        : children
      : "";
    return <>{jsUcfirst(content.toString())}</>;
  } catch (e) {
    return <>{children}</>;
  }
}
export function ShowCapitalizeFirstLetterTranslated({ text = "", children }) {
  const { t } = useTranslationWrapper();
  return (
    <ShowCapitalizeFirstLetter forceLower={false}>
      {t(children ? children : text)}
    </ShowCapitalizeFirstLetter>
  );
}

export function jsUcfirst(string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
}
