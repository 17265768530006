import { ClientTheme, ColorOptions } from "./Client";

export const GulfTheme = {
  main: {
    ...ClientTheme.main,
    primary: ColorOptions.blue,
    "blue-aqua-1": "#30cfd0 -54%",
    "blue-aqua-2": "#002776 116%",
    "gradient-peach-deg": "40deg",
    "gradient-peach-1": "#f06431",
    "gradient-peach-2": "#ff4f0f",
    graphBackgroundColor: "rgba(52, 143, 226, 0.2)",
    graphBorderColor: "rgba(52, 143, 226, 0.6)",
  },
};
