import { createContext, useContext, useMemo, useState } from "react";
import { SWRConfig } from "swr";
import { ClientThemeProvider } from "../../../styles/themes/ClientThemeProvider";
import AuthService from "../../../services/AuthService";
import DashboardService from "../../../components/Home/Service";
import {
  getLocaleByCountryId,
  setDefaultLocale,
  ShowDataProvider,
} from "@npm-packages/translations";
import { LoadingDots } from "components/UI/Loading/Loading";

export const GlobalContext = createContext();

const AUTH_TOKEN = "AUTH_TOKEN";
const COUNTRY = "COUNTRY";
const AUTH_EMAIL = "AUTH_EMAIL";
const AUTH_NAME = "AUTH_NAME";

export const COUNTRY_BR = 1;
export const COUNTRY_CO = 2;
export const COUNTRY_MX = 3;

function getInitialStorageItem(NAME) {
  return localStorage.getItem(NAME) ? localStorage.getItem(NAME) : null;
}

export default function GlobalContextProvider({ children }) {
  const [country, setCountry] = useState(getInitialStorageItem(COUNTRY));
  const [authToken, setAuthToken] = useState(getInitialStorageItem(AUTH_TOKEN));
  const [name, setName] = useState(getInitialStorageItem(AUTH_NAME));
  const [email, setEmail] = useState(getInitialStorageItem(AUTH_EMAIL));
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setisAuthenticating] = useState(false);

  useMemo(() => {
    if (email) {
      localStorage.setItem(AUTH_EMAIL, email);
    }
  }, [email]);

  useMemo(() => {
    if (name) {
      localStorage.setItem(AUTH_NAME, name);
    }
  }, [name]);

  useMemo(() => {
    if (authToken) {
      localStorage.setItem(AUTH_TOKEN, authToken);
      if (!isAuthenticated) {
        setisAuthenticating(true);
        DashboardService.userDetails()
          .then((res) => {
            let userData = res.data.user;
            setName(userData.name);
            setEmail(userData.email);
            setCountry(userData.country.id);
            setIsAuthenticated(true);
            setisAuthenticating(false);
          })
          .catch(() => {
            removeAuthToken();
            setIsAuthenticated(false);
            setisAuthenticating(false);
          });
      }
    }
  }, [authToken]);

  useMemo(() => {
    if (country) {
      localStorage.setItem(COUNTRY, country);
      setDefaultLocale(getLocaleByCountryId(country));
    }
  }, [country]);

  //function changeCountry(newCountry) {
  //  setCountry(newCountry);
  //}

  const changeUserDetail = (data) => {
    return AuthService.changeUserDetail(data).then((res) => {
      setName(data.name);
      setEmail(data.email);
      return res;
    });
  };
  const authTokenLogin = (newAuthToken) => {
    setAuthToken(newAuthToken);
  };

  const logout = () => {
    removeAuthToken();
    window.location.replace(
      `${process.env.REACT_APP_OAUTH_URL}auth/logout/2?redir=${window.location.origin}`
    );
  };

  const removeAuthToken = () => {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem(AUTH_NAME);
    localStorage.removeItem(AUTH_EMAIL);
  };

  return (
    <GlobalContext.Provider
      value={{
        country: country,
        //setCountry: changeCountry,
        isAuthenticated: isAuthenticated,
        changeUserDetail,
        name,
        email,
        logout,
        authTokenLogin,
      }}
    >
      <ClientThemeProvider>
        <SWRConfig
          value={{
            focusThrottleInterval: 60000,
            loadingTimeout: 10000,
            errorRetryInterval: 10000,
            errorRetryCount: 3,
            dedupingInterval: 10000,
          }}
        >
          <ShowDataProvider country={country}>
            {isAuthenticating ? <LoadingDots /> : children}
          </ShowDataProvider>
        </SWRConfig>
      </ClientThemeProvider>
    </GlobalContext.Provider>
  );
}

export function useGlobalContext() {
  return useContext(GlobalContext);
}

export function ShowUnlessCountry({ countryId, componentUnless, children }) {
  const { country } = useGlobalContext();
  return country === countryId ? componentUnless : children;
}
