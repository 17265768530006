export class StorageAdapter {
  static get (key, parse = false) {
    if (parse) JSON.parse(StorageAdapter.get(key))
    return localStorage.getItem(key)
  }

  static set (key, value, stringify = false) {
    if (stringify) return StorageAdapter.set(key, JSON.stringify(value))

    return localStorage.setItem(key, value)
  }

  static clean () {
    localStorage.clear()
  }
}
