import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export const Icon = ({ icon, ...props }) => (
  <FontAwesomeIcon icon={icon} {...props} />
);
export const CircleIcon = (props) => <Icon icon={faCircle} {...props} />;
export const CircleIconSuccess = () => (
  <CircleIcon className="fa-xs text-success mr-2" />
);
export const CircleIconDanger = () => (
  <CircleIcon className="fa-xs text-danger mr-2" />
);
