import { Suspense, lazy } from "react";
import { BrowserRouter } from "react-router-dom";
import { useGlobalContext } from "components/Shared/GlobalContext/GlobalContextProvider";
//import AuthenticatedRoutes from "routes/AuthenticatedRoutes";
//import LoginRoutes from "routes/LoginRoutes";
import { LoadingDots } from "components/UI/Loading/Loading";

const AuthenticatedRoutes = lazy(() => import("./AuthenticatedRoutes"));
const LoginRoutes = lazy(() => import("./LoginRoutes"));

const AppRoute = () => {
  const { isAuthenticated } = useGlobalContext();
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingDots />}>
        {isAuthenticated ? <AuthenticatedRoutes /> : <LoginRoutes />}
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoute;
