import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
   :root {
    --blue: ${(props) => props.theme.blue};
    --indigo: ${(props) => props.theme.indigo};
    --purple: ${(props) => props.theme.purple};
    --orange: ${(props) => props.theme.orange};
    --cyan: ${(props) => props.theme.cyan};
    --pink: ${(props) => props.theme.pink};
    --gray-500: ${(props) => props.theme["gray-500"]};
    --gray-600: ${(props) => props.theme["gray-600"]};
    --gray-700: ${(props) => props.theme["gray-700"]};
    --gray-800: ${(props) => props.theme["gray-800"]};
    --gray-900: ${(props) => props.theme["gray-900"]};
    --body-bg: ${(props) => props.theme["body-bg"]};
    --dark: ${(props) => props.theme.dark};
    --font-family-base: ${(props) => props.theme["font-family-base"]};
    --font-size-base: ${(props) => props.theme["font-size-base"]};
    --h1-font-size: ${(props) => props.theme["h1-font-size"]};
    --h2-font-size: ${(props) => props.theme["h2-font-size"]};
    --h3-font-size: ${(props) => props.theme["h3-font-size"]};
    --h4-font-size: ${(props) => props.theme["h4-font-size"]};
    --h5-font-size: ${(props) => props.theme["h5-font-size"]};
    --h6-font-size: ${(props) => props.theme["h6-font-size"]};
    --blue-aqua-deg:  ${(props) => props.theme["blue-aqua-deg"]};
    --blue-aqua-1:  ${(props) => props.theme["blue-aqua-1"]};
    --blue-aqua-2:  ${(props) => props.theme["blue-aqua-2"]};
    --gradient-peach-deg:  ${(props) => props.theme["gradient-peach-deg"]};
    --gradient-peach-1:  ${(props) => props.theme["gradient-peach-1"]};
    --gradient-peach-2:  ${(props) => props.theme["gradient-peach-2"]};
    --primary: ${(props) => props.theme["primary"]};
    --secondary: ${(props) => props.theme["secondary"]};
    }
    .text-primary{
        color: var(--primary)!important;
    }
   .text-primary, .navlink-menu .navLink, .page-link, a{
        color: var(--primary);
    }
    .navlink-menu .navLink.navLinkActive, .navlink-menu .navLink:hover{
       border-bottom: 1px solid var(--primary);
    }
    .bg-primary {
        background-color: var(--primary) !important;
    }
    .btn-primary, .page-item.active .page-link{
        background-color: var(--primary);
        border-color:var(--primary);
    }
    body{
        font-size: var(--font-size-base)
    }
    .bg-pink{
        background-color: var(--pink);
    }
    .progress-bar{
        background-color: var(--primary);
    }

    nav#sidebar li {
        white-space: nowrap;
    }
`;
