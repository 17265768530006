import { ThemeProvider } from "styled-components";
import { createContext, useContext, useState } from "react";
import { GlobalStyles } from "./GlobalStyles";
import { getThemeOption, getThemeOptionByEnv } from "./ThemesOptions";

export const ClientContext = createContext();

export const ClientThemeProvider = ({ children }) => {
  const [themeOptions, setThemeOptions] = useState(getThemeOptionByEnv());
  const [theme, setTheme] = useState(themeOptions.theme.main);

  function changeTheme(theme) {
    let themeOption = getThemeOption(theme);
    setThemeOptions(themeOption);
    setTheme(themeOption.theme.main);
  }

  return (
    <ClientContext.Provider
      value={{
        TitleName: themeOptions.title,
        logo: themeOptions.logo,
        graphBorderColor: theme.graphBorderColor,
        graphBackgroundColor: theme.graphBackgroundColor,
        banner: themeOptions.banner,
        changeTheme: changeTheme,
      }}
    >
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </ClientContext.Provider>
  );
};

export function useClientContext() {
  return useContext(ClientContext);
}
