import { ClientTheme, ColorOptions } from "./Client";

export const MovilizateTheme = {
  main: {
    ...ClientTheme.main,
    primary: ColorOptions.blue,
    "gradient-peach-deg": "40deg",
    "blue-aqua-1": "#104c96",
    "blue-aqua-2": "#2377de",
    "gradient-peach-1": "#EB1B2D -54%",
    "gradient-peach-2": "#6f151c 116%",
    graphBackgroundColor: "rgba(52, 143, 226, 0.2)",
    graphBorderColor: "rgba(52, 143, 226, 0.6)",
  },
};
