import axios from "axios";
var JSONbigNative = require("json-bigint")({ storeAsString: true });

class Service {
  constructor(
    url = "",
    baseUrl = process.env.REACT_APP_API_ENDPOINT_API_GATEWAY,
    useInterceptor = true
  ) {
    this.isCompound = false;
    this.url = url;
    this.baseUrl = baseUrl;
    this.useInterceptor = useInterceptor;
  }

  _prepareClient(id = undefined) {
    this.client = axios.create({
      baseURL: this.baseUrl,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN"),
      },
      transformResponse: [
        (data) => {
          if (typeof data === "string") {
            try {
              data = JSONbigNative.parse(data);
            } catch (e) {
              /* Ignore */
            } // Added this Ignore as it's the same in the Axios
          }
          return data;
        },
      ],
    });

    if (this.useInterceptor) {
      this.client.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          if (error.response) {
            if (401 === error.response.status) {
              // window.store.dispatch("logout");
            }

            if (403 === error.response.status) {
              window.location = "/";
            }
            if (422 === error.response.status) {
              return Promise.reject(error);
            }
            if (500 === error.response.status) {
              return Promise.reject(error);
            }
          }
          // alert('Ocorreu um erro de comunicação com o servidor');
          //  return Promise.resolve(false);
        }
      );
    }

    if (id) {
      this.url = this._prepareUrl(id);
    }
  }

  isEmpty(object = {}) {
    for (var key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        return false;
      }
    }
    return true;
  }

  hasQueryString(object = {}) {
    return !this.isEmpty(object);
  }

  prepare() {
    this._prepareClient();
  }

  _getUrl(queryString = {}) {
    if (this.hasQueryString(queryString)) {
      return this.url + queryString;
    }

    return this.url;
  }

  request(method) {
    this.prepare();
    return this.client[method](this.url);
  }

  get(queryString = {}) {
    this.prepare();
    return this.client.get(this._getUrl(queryString));
  }

  static build() {
    return new this(this);
  }
}

export default Service;
