import { parseISO, isMatch, format } from "date-fns";
import { useShowDataContext } from "@npm-packages/translations";

export function ShowDate({ date, dateMask = SHOWDATE_DB_DATETIME }) {
  const { dateLocale } = useShowDataContext();
  return <>{getDate(date, dateLocale, dateMask)}</>;
}

export function ShowDateTime({ date, dateMask = SHOWDATE_DB_DATETIME }) {
  const { dateLocale } = useShowDataContext();
  return (
    <>{getDate(date, dateLocale, dateMask, SHOWDATE_MASK_DATETIME_MONTHNAME)}</>
  );
}

export function ShowHourInteger({ hour }) {
  return (Number.isInteger(hour) && hour < 10 ? "0" + hour : hour) + ":00";
}

export const SHOWDATE_DB_DATETIME = "yyyy-MM-dd HH:mm:ss";
export const SHOWDATE_TIME = "HH:mm:ss";
export const SHOWDATE_DB_DATETIME_TZ = "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'";
export const SHOWDATE_DB_DATE = "yyyy-MM-dd";
export const SHOWDATE_MASK_DATE = "P"; //05/29/1453
export const SHOWDATE_MASK_DATE_MONTHNAME = "PP"; //May 29, 1453
export const SHOWDATE_MASK_DATETIME = "Pp"; //05/29/1453, 12:00 AM
export const SHOWDATE_MASK_DATETIME_MONTHNAME = "PPpp"; //May 29, 1453, 12:00:00 AM

export function useGetMonthName() {
  const { dateLocale } = useShowDataContext();
  function getMonthName(monthNum, mask = "MMM") {
    return format(new Date(2020, monthNum, 1), mask, { locale: dateLocale });
  }
  return { getMonthName };
}
function getDate(
  dateString,
  dateLocale,
  dateStringMask = SHOWDATE_DB_DATETIME,
  mask = SHOWDATE_MASK_DATE_MONTHNAME
) {
  let value = "";
  if (isMatch(dateString, dateStringMask)) {
    const date = parseISO(dateString);
    value = format(date, mask, { locale: dateLocale });
  }
  return value;
}

export function extractDate(dateString, dateStringMask = SHOWDATE_DB_DATETIME) {
  if (isMatch(dateString, dateStringMask)) {
    return parseISO(dateString);
  }
  return new Date();
}

export function yesterday() {
  return new Date(Date.now() - 86400000)
}