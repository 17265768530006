export const ColorOptions = {
  blue: "#002776",
  indigo: "#2A6EBB",
  purple: "#6f42c1",
  orange: "#FF6319",
  cyan: "#49b6d6",
  pink: "#fb5597",
};

export const ClientTheme = {
  main: {
    ...ColorOptions,
    //
    secondary: ColorOptions.indigo,
    "gray-500": "#D1D4D3",
    "gray-600": "#A5ACAF",
    "gray-700": "#7E848B",
    "gray-800": "#6C6F70",
    "gray-900": "#212529",
    "body-bg": "var(--gray-500)",
    dark: "var(--gray-900)",
    "font-family-base": "'Open Sans', sans-serif",
    "font-size-base": "0.9rem",
    "h1-font-size": "calc(var(--font-size-base) * 2)",
    "h2-font-size": "calc(var(--font-size-base) * 1.75)",
    "h3-font-size": "calc(var(--font-size-base) * 1.5)",
    "h4-font-size": "calc(var(--font-size-base) * 1.25)",
    "h5-font-size": "var(--font-size-base)",
    "h6-font-size": "var(--font-size-base)",
    "blue-aqua-deg": "20deg",
    orange: "#EB1B2D",
    "blue-aqua-1": "#EB1B2D -54%",
    "blue-aqua-2": "#6f151c 116%",
    "gradient-peach-deg": "40deg",
    "gradient-peach-1": "#104c96",
    "gradient-peach-2": "#2377de",
    primary: "#6f151c",
    graphBackgroundColor: "rgba(111, 21, 28, 0.2)",
    graphBorderColor: "rgba(111, 21, 28, 0.6)",
  },
  darker: {},
};
