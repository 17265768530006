import React, {useCallback, useState} from 'react'
import {useTranslationWrapper} from '@npm-packages/translations'
import * as fa from '@fortawesome/free-solid-svg-icons'
import {Icon} from '../UI/Icon/Icon'
import './style.scss'
import { StorageAdapter } from "../../Utils/StorageAdapt"
import { ShowCapitalizeFirstLetterTranslated } from "../UI/ShowData/ShowCapitalizeFirstLetter";

const COOKIE_ACCEPT = 'COOKIE_ACCEPT'

const CookiePolicy = ({ children }) => {
  const { t } = useTranslationWrapper()
  const message = t('cookie_policy_message')
  const [show, setShow] = useState(!Boolean(StorageAdapter.get(COOKIE_ACCEPT)))

  const handleClickClose = useCallback(() => {
    StorageAdapter.set(COOKIE_ACCEPT, '1')
    setShow(false)
  }, [])

  return (
    <>
      {children}
      {show && (
        <div className="cookie-policy">
          <ShowCapitalizeFirstLetterTranslated text={message} />
          <div className="btn-close" onClick={() => handleClickClose()}>
            <Icon className="ml-2" icon={fa.faTimes} />
          </div>
        </div>
      )}
    </>
  )
}

export default CookiePolicy;
