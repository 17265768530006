export default function Loading() {
  return <LoadingDots />;
}

export function LoadingDots() {
  return (
    <div className="cssload-wrap">
      <div className="cssload-container">
        <span className="cssload-dots" />
        <span className="cssload-dots" />
        <span className="cssload-dots" />
        <span className="cssload-dots" />
        <span className="cssload-dots" />
        <span className="cssload-dots" />
        <span className="cssload-dots" />
        <span className="cssload-dots" />
        <span className="cssload-dots" />
        <span className="cssload-dots" />
      </div>
    </div>
  );
}
