import ResourceService from "./ResourceService";

class AuthService extends ResourceService {
  constructor() {
    super("/oauth/token", process.env.REACT_APP_API_ENDPOINT, false);
  }

  changeUserDetail(data) {
    //this.baseUrl = 'http://localhost:3006/'
    this.prepare();
    return this.client.put("/user/light", data);
  }

  logout() {
    this.prepare();
    return this.client.get("/auth/logout");
  }

  changePassword() {
    //this.prepare();
    //return this.client.post("/change/password", data);
  }

  resetPassword() {
    //this.prepare();
    //return this.client.post("/api/user/", data);
  }
}

const AuthInstance = new AuthService();

export default AuthInstance;
