import Service from "./Service";

class ResourceService extends Service {
  show(id) {
    this.prepare();
    return this.client.get(this.url + "/" + id);
  }

  post(data, url = this.url) {
    this.prepare();
    return this.client.post(url, data);
  }

  update(id, data = {}) {
    this.prepare();
    return this.client.put(this.url + "/" + id, data);
  }

  delete(id) {
    this.prepare();
    return this.client.delete(this.url + "/" + id);
  }
}

export default ResourceService;
