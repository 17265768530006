import React from "react";
import AppRoute from "./routes/AppRoute";
import GlobalContextProvider from "./components/Shared/GlobalContext/GlobalContextProvider";
import CookiePolicy from "./components/CookiePolicy";

export default function App() {
  return (
    <GlobalContextProvider>
      <CookiePolicy>
        <AppRoute />
      </CookiePolicy>
    </GlobalContextProvider>
  );
}
