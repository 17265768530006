import Service from "services/Service";
import { format } from "date-fns";
import { SHOWDATE_DB_DATE } from "components/UI/ShowData/ShowDate";

class DashboardService extends Service {
  constructor() {
    super("dashboard");
  }

  userDetails() {
    this.prepare();
    return this.client.get(this.url + "/user/details");
  }

  states() {
    this.prepare();
    return this.client.get(this.url + "/ufs");
  }

  city(stateId) {
    this.prepare();
    return this.client.get(this.url + "/cities/" + stateId);
  }

  bank() {
    this.prepare();
    return this.client.get(this.url + "/bank");
  }

  graph(date) {
    this.prepare();
    return this.client.get(
      `${this.url}/graph?from=${format(date, SHOWDATE_DB_DATE)}`
    );
  }
}

export default new DashboardService();
